import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Button } from "antd";
import { boundError } from "../utlis";
import astronaut from "../../assets/images/astronaut.svg";
import { Background } from "./mainLayout/Background";
import { isMobile } from "react-device-detect";
import checkmark from "../../assets/images/checkmark.svg";
import dollar from "../../assets/images/dollar.svg";
import globe from "../../assets/images/globe.svg";
import fire from "../../assets/images/fire.svg";
import individual from "../../assets/images/individual.svg";
import group from "../../assets/images/group.svg";
import company from "../../assets/images/company.svg";

const LandingRaw = () => {
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {}, []);

  return (
    <Spin spinning={loading}>
      <div className="portion-1">
        <div className="paragraph-container">
          <span className="title">EZAT BLOCKCHAIN SERVICE</span>
          <span className="description">
            Pay-as-you-go blockchain service platform to accelerate your blockchain application development cycle, and
            minimize your initial cost
          </span>
          <Button
            className="get-started-button"
            type="primary"
            onClick={() => {
              window.open("https://console.ebs.ezat.io/", "_blank");
            }}
          >
            GET STARTED FOR FREE
          </Button>
        </div>
        <img style={{ zIndex: 1 }} src={astronaut} />
      </div>
      <div className="portion-2">
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 30
          }}
        >
          <span style={{ fontSize: 32, marginBottom: 20 }}>What is EZAT Blockchain Service?</span>
          <span style={{ fontSize: 20 }}>
            We provide tools to help accelerate blockchain projects development cycle, minimize total costs, and
            mitigate risks
          </span>
        </div>
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row"
          }}
        >
          {/* <div
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              width: isMobile ? "100%" : "49%",
              padding: isMobile ? 10 : 40,
              marginBottom: 10
            }}
          >
            <p style={{ textAlign: "center", fontSize: 22, fontWeight: 700 }}>Blockchain Automation</p>
            <ul style={{ fontSize: 20, lineHeight: 2 }}>
              <li>
                <span style={{ fontWeight: 700 }}>Token forwarding</span> - allows projects to build payment gateway
                quickly, collect crypto payments, and aggregate funds.
              </li>
              <li>
                <span style={{ fontWeight: 700 }}>Address monitoring</span> - allow projects to track smart contract
                events, and address transactions easily in real time
              </li>
              <li>
                <span style={{ fontWeight: 700 }}>Wallet custody</span> - allow projects to have access to auditable
                custodial wallets through APIs
              </li>
            </ul>
          </div> */}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              // width: isMobile ? "100%" : "49%",
              padding: isMobile ? 10 : 20, // 40
              marginBottom: 10
            }}
          >
            <p style={{ textAlign: "center", fontSize: 22, fontWeight: 700 }}>Blockchain Data Access</p>
            <ul style={{ fontSize: 20, lineHeight: 2 }}>
              <li>
                <span style={{ fontWeight: 700 }}>Blockchain node RPC endpoints</span> - allow projects to quickly
                connect and interact with a running blockchain node
              </li>
              <li>
                <span style={{ fontWeight: 700 }}>NFT indexer</span> - allows projects to get cross-chain NFT
                collection, address, name, and image in real-time
              </li>
              <li>
                <span style={{ fontWeight: 700 }}>Transaction/Balance/ERC20 indexer</span> - allows projects to quickly
                get addresses' historical transactions and balances
              </li>
            </ul>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="get-started-button-invert"
            type="primary"
            onClick={() => {
              window.open("https://console.ebs.ezat.io/", "_blank");
            }}
          >
            GET STARTED FOR FREE
          </Button>
        </div>
      </div>
      <div className="portion-3">
        <div style={{ fontSize: 32 }}>We commit to offer</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: 40,
            flexDirection: isMobile ? "column" : "row"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
            <img style={{ width: 120, height: 120, margin: 20 }} src={dollar} />
            <div>
              An AWS like pay-as-you-go subscription model, with no service bundling, presents a cost-efficient,
              flexible, customizable solution for Web3 projects
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
            <img style={{ width: 120, height: 120, margin: 20 }} src={checkmark} />
            <div>
              Secure and auditable infrastructure along with the technology that ensures data accuracy and assets safety
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
            <img style={{ width: 120, height: 120, margin: 20 }} src={globe} />
            <div>Global infrastructure that guarantees highly reliable, scalable, and available services</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
            <img style={{ width: 120, height: 120, margin: 20 }} src={fire} />
            <div>Single platform to access all essential services for Web3 development</div>
          </div>
        </div>
      </div>
      <div className="portion-4">
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 30
          }}
        >
          <div style={{ fontSize: 32, textAlign: "center" }}>Anyone Web3 team benefit from EZAT platform</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: 40,
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
              <img style={{ width: 120, height: 120, margin: 20 }} src={individual} />
              <div style={{ fontWeight: 600 }}>Individual Web3 Enthusiast</div>
              <div style={{ textAlign: "center" }}>
                With free Welcome credits, individual developers will most likely not need to spend any money to
                validate their ideas.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
              <img style={{ width: 120, height: 120, margin: 20 }} src={group} />
              <div style={{ fontWeight: 600 }}>Startups</div>
              <div style={{ textAlign: "center" }}>
                With a pay-as-you-go model and a unified platform, startups will be able to quickly adopt the tools they
                need in the most cost-efficient way.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 250 }}>
              <img style={{ width: 120, height: 120, margin: 20 }} src={company} />
              <div style={{ fontWeight: 600 }}>Large Organizations</div>
              <div style={{ textAlign: "center" }}>
                Price tiering allows large organizations to have lower unit prices. Secure infrastructure and reliable
                service availability ensure organizations' smooth operations.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* background */}
      <Background />
    </Spin>
  );
};

const Landing = boundError(LandingRaw);
export default Landing;
