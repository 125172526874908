import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Header } from "./Header";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import Sider from "antd/es/layout/Sider";

const { Content } = Layout;

const MainLayoutRaw = () => {
  useEffect(() => {}, []);

  return (
    <Layout>
      <Header />
      <Layout
        style={{
          backgroundColor: "white"
        }}
      >
        <Content
          style={{
            margin: "0",
            overflow: "initial",
            minHeight: "100vh",
            backgroundColor: "white",
            transition: "all 0.2s ease 0s"
          }}
        >
          <div style={{ paddingTop: 80 }}>
            <Outlet />
          </div>
        </Content>
      </Layout>

      <Layout className="site-layout"></Layout>
      {/* <BottomNavBar /> */}
    </Layout>
  );
};

export const MainLayout = boundError(MainLayoutRaw);
