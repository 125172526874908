// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.center-container {
  justify-content: center; }

.margin-none {
  margin: 0px; }

a:hover {
  text-decoration: none; }

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 8px; }

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
`, "",{"version":3,"sources":["webpack://./src/styles/base.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;;AAEzB;EACE,uBAAuB,EAAA;;AAEzB;EACE,WAAW,EAAA;;AAEb;EACE,qBAAqB,EAAA;;AAOvB;EACE,wBAAwB;EACxB,UAAU;EACV,WAAW,EAAA;;AAEb;EACE,kBAAkB;EAClB,oCAAoC;EACpC,4CAA4C,EAAA","sourcesContent":["@import \"./variables.scss\";\n\n.div-flex-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.center-container {\n  justify-content: center;\n}\n.margin-none {\n  margin: 0px;\n}\na:hover {\n  text-decoration: none;\n}\n\n@media only screen and (max-width: $screen-md-min) {\n}\n\n// for iOS macOS\n::-webkit-scrollbar {\n  -webkit-appearance: none;\n  width: 3px;\n  height: 8px;\n}\n::-webkit-scrollbar-thumb {\n  border-radius: 4px;\n  background-color: rgba(0, 0, 0, 0.5);\n  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
