import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "./styles/base.scss";
import "./styles/antd.scss";
import "./styles/atoms.scss";
import "./styles/mainLayout.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
