import axios from "axios";

const decorateAxiosForApi = ({ apiBaseUrl, overwriteIdToken }: any = {}) => {
  const decoratedAxios = axios.create({ baseURL: apiBaseUrl });

  return decoratedAxios;
};

// eslint-disable-next-line import/no-mutable-exports
let apiAxios = decorateAxiosForApi({ apiBaseUrl: process.env.API_BASE_URL });

export const concatApiQuery = (api: string, data: any) => {
  if (data) {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== undefined) {
        if (i === 0) {
          api = api + "?";
        } else {
          api = api + "&";
        }
        api = api + `${keys[i]}=${data[keys[i]]}`;
      }
    }
  }
  return api;
};

export const responseDataHandler = (data: any) => {
  if (data?.statusCode >= 200 || data?.status < 300) {
    return data?.data;
  } else {
    if (data?.message) throw new Error(data?.message);

    throw new Error("Unknown error!");
  }
};

export const callApi = (
  api: string,
  { method = "GET", data, headers }: { method?: any; data?: any; headers?: any } = {}
) => {
  return apiAxios({
    url: api,
    method,
    data,
    headers: {
      ...headers
    }
  });
};

export { apiAxios };
