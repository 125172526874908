import { Layout, Dropdown, Menu, Drawer, Button, Image, MenuProps, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { isMobile } from "react-device-detect";
import { SettingOutlined } from "@ant-design/icons";
import { showError } from "../../utlis/messages";
import logo from "../../../assets/images/dark.svg";

const { Header: AntHeader } = Layout;

const HeaderRaw = () => {
  const navigate = useNavigate();

  return (
    <AntHeader className="sticky-top">
      <div className="header-container">
        <div
          style={{
            display: "flex",
            height: 64
          }}
        >
          <Button
            style={{
              padding: 0,
              height: 60,
              display: "flex"
            }}
            type="link"
            onClick={() => {
              navigate("/");
            }}
          >
            <img style={{ height: isMobile ? 45 : 60 }} src={logo} />
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Space size={isMobile ? 5 : "large"}>
            <Button
              style={{
                padding: 0,
                fontWeight: 500,
                color: "black"
              }}
              type="link"
              onClick={() => {
                window.open("https://docs.ebs.ezat.io/ebs-documentation/", "_blank");
              }}
            >
              Documents
            </Button>
            <Button
              style={{
                padding: 0,
                fontWeight: 500,
                color: "black"
              }}
              type="link"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact us
            </Button>
            <Button
              style={{
                color: "#1565D8",
                border: "1px solid #1565D8",
                borderRadius: 8,
                fontWeight: 500
              }}
              type="primary"
              ghost
              onClick={() => {
                window.open("https://console.ebs.ezat.io/", "_blank");
              }}
            >
              Console
            </Button>
          </Space>
        </div>
      </div>
    </AntHeader>
  );
};

export const Header = boundError(HeaderRaw);
