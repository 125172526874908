import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Button } from "antd";
import { boundError } from "../utlis";
import astronaut from "../../assets/images/astronaut.svg";
import { Background } from "./mainLayout/Background";
import TextArea from "antd/es/input/TextArea";
import { showError, showSuccess } from "../utlis/messages";
import { doContactUs } from "../../apis/apis";

const ContactUsRaw = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {}, []);

  const sendRequest = async (values) => {
    try {
      setLoading(true);
      if (!name) {
        return showError("Please enter a name");
      }
      if (!email) {
        return showError("Please enter an email");
      }
      if (!message) {
        return showError("Please enter a message");
      }
      await doContactUs(name, email, message);
      showSuccess("Your message has been sent to us!");
      form.resetFields();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container">
        <div className="paragraph-container">
          <span className="title">Contact us</span>
          <Form
            style={{ padding: "20px" }}
            onFinish={sendRequest}
            layout="vertical"
            form={form}
            name="login-form"
            id="login-form"
          >
            <Form.Item label="Name" name="name">
              <Input
                allowClear
                id="name"
                autoFocus
                type="name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Email" name="email" normalize={(value) => value.replace(" ", "").toLowerCase()}>
              <Input
                allowClear
                id="email"
                autoFocus
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Message" name="message">
              <TextArea
                allowClear
                showCount
                maxLength={200}
                style={{ height: 120, marginBottom: 24, zIndex: 1 }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Form.Item>

            <Button htmlType="submit" type="primary" style={{ zIndex: 1 }}>
              Send
            </Button>
          </Form>
        </div>

        <img style={{ zIndex: 1, background: "white" }} src={astronaut} />
      </div>

      {/* background */}
      <Background />
    </Spin>
  );
};

const ContactUs = boundError(ContactUsRaw);
export default ContactUs;
