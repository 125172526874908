import { useEffect, useState } from "react";
import { boundError } from "./components/utlis";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Landing from "./components/pages/Landing";
import { MainLayout } from "./components/pages/mainLayout/MainLayout";
import ContactUs from "./components/pages/ContactUs";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Landing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default boundError(App);
