import { boundError } from "../../utlis/boundError";

const BackgroundRaw = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "45%",
          top: "-50px"
        }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            background: "#004DBD"
          }}
        ></div>
      </div>
      <div
        style={{
          position: "absolute",
          // left: "45%",
          top: "50px"
        }}
      >
        <div
          style={{
            width: 28,
            height: 48,
            background: "#004DBD"
          }}
        ></div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "10%",
          top: "50vh"
        }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            background: "#004DBD"
          }}
        ></div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "70vw",
          top: "60vh"
        }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            background: "#90B9F3"
          }}
        ></div>
      </div>
    </>
  );
};

export const Background = boundError(BackgroundRaw);
