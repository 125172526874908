import { notification } from "antd";

export const showMessage = (message) => {
  notification.destroy();
  notification?.info({
    message
  });
};

export const showSuccess = (message) => {
  notification.destroy();
  notification?.success({
    message
  });
};

export const showWarning = (message) => {
  notification.destroy();
  notification?.warning({
    message
  });
};

export const showError = (error, description = "") => {
  notification.destroy();
  if (error?.message) {
    notification?.error({
      message: error?.message?.toString(),
      description
    });
  } else {
    notification?.error({
      message: error?.toString(),
      description
    });
  }
};
